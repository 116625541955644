body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.rainbow_font{
  background: linear-gradient(to right,#e60000,#f39800,#fff100,#009944,#0068b7,#1d2088,#920783) 0 / 200%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 300%;
  font-weight: bold;
  display: inline-block;
  animation: 5s rainbow_font linear infinite;
}
@keyframes rainbow_font{
  100% { background-position: 200%; }
}

.rainbow_box{
  background: linear-gradient(to right,#e60000,#f39800,#fff100,#009944,#0068b7,#1d2088,#920783,#e60000) 0 / 200%;
  height: 80px;
  animation: 5s rainbow_box linear infinite;
}
@keyframes rainbow_box{
  100% { background-position: 200%; }
}